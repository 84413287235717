<script lang="ts">
	import ProductGroupCard from "./ProductGroupCard.svelte";
	import type { ProductGroupWithTextifiedContent } from "../../../core/schema/ProductGroup.js";

	export let productGroups: ProductGroupWithTextifiedContent[];
	export let showProductsOfGroup = false;
	export let topOffset = true;
</script>

<div class="flex flex-col {topOffset ? 'py-36' : 'pb-36'}">
	{#each productGroups as group, index (group.id)}
		{#if showProductsOfGroup}
			{#each group.products as product, index (product.name)}
				<ProductGroupCard index={index + 1} {group} {product} />
			{/each}
		{:else}
			<ProductGroupCard index={index + 1} {group} />
		{/if}
	{/each}
</div>
